import {
  alpha,
  Badge,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  Popover,
  Tooltip,
  Typography
} from '@mui/material';
import { useRef, useState } from 'react';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import { styled } from '@mui/material/styles';

import { formatDistance, subDays } from 'date-fns';

const NotificationsBadge = styled(Badge)(
  ({ theme }) => `
    
    .MuiBadge-badge {
        background-color: ${alpha(theme.palette.error.main, 0.1)};
        color: ${theme.palette.error.main};
        min-width: 16px; 
        height: 16px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`
);

function NotificationRowItem() {
  return (
    <Box flex="1">
      <Box display="flex" justifyContent="space-between">
        <Typography sx={{ fontWeight: 'bold' }}>Messaging Platform</Typography>
        <Typography variant="caption" sx={{ textTransform: 'none' }}>
          {formatDistance(subDays(new Date(), 3), new Date(), {
            addSuffix: true
          })}
        </Typography>
      </Box>
      <Typography component="span" variant="body2" color="text.secondary">
        {' '}
        new messages in your inbox
      </Typography>
    </Box>
  );
}

function HeaderNotifications() {
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const notificationCount = 0;

  return (
    <>
      <Tooltip arrow title="Notifications">
        <IconButton color="primary" ref={ref} onClick={handleOpen}>
          {notificationCount > 0 ? (
            <NotificationsBadge
              badgeContent={0}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            >
              <NotificationsActiveTwoToneIcon />
            </NotificationsBadge>
          ) : (
            <NotificationsActiveTwoToneIcon />
          )}
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box
          sx={{ p: 2 }}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h5">Notifications</Typography>
        </Box>
        <Divider />
        <List sx={{ p: 0 }}>
          <ListItem
            sx={{ p: 2, minWidth: 350, display: { xs: 'block', sm: 'flex' } }}
          >
            No notifications yet!
            {/* TODO: List notification items here. */}
          </ListItem>
        </List>
      </Popover>
    </>
  );
}

export default HeaderNotifications;
