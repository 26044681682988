import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword
} from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { useAuth as useReactFireAuth } from 'reactfire';

import useRequireLogin from './useRequireLogin';

const firebaseConfigJson: string | undefined =
  process.env.REACT_APP_FIREBASE_CONFIG;
if (!firebaseConfigJson) {
  throw Error(
    'Cannot load firebase config - REACT_APP_FIREBASE_CONFIG missing.'
  );
}

interface FirebaseConfig {
  apiKey: string;
  appId: string;
  authDomain: string;
  messagingSenderId: string;
  projectId: string;
  storageBucket: string;
}

const firebaseConfig = JSON.parse(firebaseConfigJson) as FirebaseConfig;

/**
 * useAuth is a hook that wraps the `useAuth` hook provided by the reactfire
 * library. It returns various convenience functions related to authentication,
 * such as updating and/or resetting local reactiveVars, calling mutations where
 * necessary, and other details that coincide with calls to various Firebase
 * authentication features.
 */
const useAuth = () => {
  const auth = useReactFireAuth();
  const authenticated = useRequireLogin(false);
  const navigate = useNavigate();

  return {
    /**
     * The Firebase Auth instance, as returned from `reactfire`'s `useAuth`.
     */
    auth,
    /**
     * Indicates if the user is authenticated, as returned from the
     * `useRequireLogin` hook.
     */
    authenticated,
    register: async (email: string, password: string) =>
      createUserWithEmailAndPassword(auth, email, password),
    /**
     * The email associated with the authenticated user in Firebase.
     */
    email: auth.currentUser?.email,
    /**
     * Returns a JSON Web Token (JWT) used to identify the user to Firebase. If
     * the token is five minutes or less from expiry (or has already expired),
     * it will be refreshed a new one will be returned.
     */
    getToken: async () => {
      if (!authenticated || !auth.currentUser) {
        // eslint-disable-next-line no-console
        const storedTokenDataBlob = localStorage.getItem('tenet-auth-token');
        if (storedTokenDataBlob) {
          const storedTokenData = JSON.parse(storedTokenDataBlob);
          const expiry = storedTokenData.expiry;
          if (expiry < new Date().getTime()) {
            return storedTokenData.token;
          }
        }

        console.warn('Attempted to get token while unauthenticated');
        navigate('/');
      } else {
        return auth.currentUser?.getIdToken();
      }
    },
    /**
     * Attempts to start the login process for a user by calling the
     * `sendSignInEmail` mutation with the provided email (and optionally, a
     * loan ID).
     */
    logIn: async ({ email, password }: { email: string; password: string }) => {
      const result = await signInWithEmailAndPassword(auth, email, password);
      const token = await result.user.getIdToken();
      const tokenStorageObject = {
        token: token,
        expiry: new Date().getTime() + 3600 * 0.75 // 75% of the token's lifetime
      };
      const blob = JSON.stringify(tokenStorageObject);
      console.log('Storing new token locally.');
      localStorage.setItem('tenet-auth-token', blob);
      return token;
    },
    /**
     * Logs out the authenticated user, and redirect them to the home page.
     */
    logOut: async (shouldRedirect = true) => {
      await auth.signOut();
      if (shouldRedirect) {
        navigate('/');
      }
    }
  };
};

export { useAuth, firebaseConfig };
