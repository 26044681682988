import { getAuth, isSignInWithEmailLink } from 'firebase/auth';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSigninCheck } from 'reactfire';

/**
 * useRequireLogin checks for an existing signed in user, and returns a boolean
 * indicating if one has been found. This allows us to block rendering of pages
 * or other components that require authentication.
 *
 * Note that the sign-in check is async, and this hook will return false until
 * it has been resolved.
 *
 * If the check determines the user has not signed in, this hook may optionally
 * redirect them to the login screen. This is configurable by passing a boolean
 * argument, and is enabled by default.
 */
const useRequireLogin = (redirect = true) => {
  const auth = getAuth();
  const { data, status } = useSigninCheck();
  const { signedIn } = data || {};
  const navigate = useNavigate();

  let isCompletingSignIn = false;

  if (typeof window !== 'undefined') {
    isCompletingSignIn = isSignInWithEmailLink(auth, window?.location.href);
  }

  const shouldRedirect =
    !isCompletingSignIn && !signedIn && redirect && status === 'success';

  useEffect(() => {
    if (shouldRedirect) {
      navigate('/');
    }
  }, [shouldRedirect]);

  return !!signedIn && status === 'success';
};

export default useRequireLogin;
