import { Card, Container, Grid } from '@mui/material';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { useAuth } from 'src/hooks/auth/useAuth';
import { badBeep, goodBeep } from 'src/util/beeps';
import { quickLittlePost } from 'src/util/quickLittleFetch';
import s from './style.module.scss';

enum Tabs {
  GENERATE_PAYMENT_FILE
}

type WestlakePaymentFileRow = {
  referenceNumber: string;
  vin: string;
  amount: string;
  effectiveDate: Date;
};

function AddPaymentFileRow({ handleAddRow }) {
  const [referenceNumber, setReferenceNumber] = useState<string | null>();
  const [vin, setVin] = useState<string | null>();
  const [amount, setAmount] = useState<string | null>();
  const [effectiveDate, setEffectiveDate] = useState<string | null>();

  const handleSubmit = (e) => {
    e.preventDefault();
    handleAddRow({ referenceNumber, vin, amount, effectiveDate });
    setReferenceNumber('');
    setVin('');
    setAmount('');
    setEffectiveDate('');
  };

  const isComplete = referenceNumber && vin && amount && effectiveDate;

  return (
    <Card className={s.addPaymentRowCard}>
      <form onSubmit={handleSubmit}>
        <p>
          Rerence Number (provided in the 1st column of the funding file as
          "ACC_NBR"): &nbsp;
          <input
            value={referenceNumber}
            onChange={(e) => {
              setReferenceNumber(e.target.value);
            }}
          />
        </p>
        <p>
          VIN (VIN for this loan's vehicle, found in Digifi): &nbsp;
          <input
            value={vin}
            onChange={(e) => {
              setVin(e.target.value);
            }}
          />
        </p>
        <p>
          Amount (wire transfer amount in dollars): &nbsp;
          <input
            value={amount}
            onChange={(e) => {
              setAmount(e.target.value);
            }}
          />
        </p>
        <p>
          Effective Date (of the wire transfer, format MM/DD/YYYY): &nbsp;
          <input
            value={effectiveDate}
            onChange={(e) => {
              setEffectiveDate(e.target.value);
            }}
          />
        </p>
        <button
          type="submit"
          className={`${s.tablink} ${s.selected}`}
          disabled={!isComplete}
        >
          Add to CSV
        </button>
      </form>
    </Card>
  );
}

export default function WestlakeToolsPage() {
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(Tabs.GENERATE_PAYMENT_FILE);
  const [rows, setRows] = useState<WestlakePaymentFileRow[]>([]);

  const auth = useAuth();
  const navigate = useNavigate();

  const handleAddRow = (newRowData: WestlakePaymentFileRow) => {
    setError('');
    setSuccess('');
    setRows([...rows, newRowData]);
  };

  const uploadPaymentFile = async (e) => {
    if (!rows.length) {
      badBeep();
      setError("Click 'Add to CSV' to save these inputs");
      setSuccess('');
      return;
    }
    setLoading(true);
    setError('');
    setSuccess('');
    e.preventDefault();

    try {
      await quickLittlePost(
        `westlake/payment-file`,
        () => {
          console.log('Payment file generation complete!');
        },
        {
          wireTransfers: rows
        },
        navigate,
        auth
      );
      goodBeep();
      setSuccess('Payment file Generated and Uploaded to S3!');
      return;
    } catch (e) {
      console.log(e);
      badBeep();
      const errorMessage =
        e.response.status == 400
          ? 'Payment file daily upload limit reached. Try again tomorrow.'
          : 'Failed to generate CSV. Make sure to hit "Add to CSV"';
      setError(errorMessage);
      return;
    } finally {
      setLoading(false);
    }
  };

  const clearConfig = (e) => {
    e.preventDefault();
    setRows([]);
    setSuccess('');
    setError('');
  };

  return (
    <>
      <Helmet>
        <title>Dealership</title>
      </Helmet>
      <PageTitleWrapper>Tenet / Admin</PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card style={{ padding: '24px' }}>
                  {/* Error and success info */}
                  {error && (
                    <p>
                      <h2 style={{ color: '#961111' }}>{error}</h2>
                    </p>
                  )}
                  {success && (
                    <p>
                      <h2 style={{ color: 'green' }}>{success}</h2>
                    </p>
                  )}
                  {/* Header */}
                  <h1>Westlake Tools</h1>
                  {/* Pseudo tab section */}
                  {/* <div className={s.pseudotabs}>
                    <button
                      className={`${s.tablink} ${
                        activeTab === Tabs.GENERATE_PAYMENT_FILE && s.selected
                      }`}
                      onClick={() => setActiveTab(Tabs.GENERATE_PAYMENT_FILE)}
                    >
                      Payment File
                    </button>
                  </div> */}
                  {/* Payment File tab */}
                  {activeTab === Tabs.GENERATE_PAYMENT_FILE && (
                    <>
                      <h2>Generate & Upload Payment File</h2>
                      <p>
                        This tools accepts wire transfer information, generates
                        a payment CSV file and uploads it to S3, according to
                        Westlake's requirements.
                      </p>
                      <p>
                        After initiating a wire transfer to purchase a loan, we
                        need to send this CSV to trigger the upload of boarding
                        data tapes that we can later ingest.{' '}
                      </p>
                      <p>
                        *Note: There can only be ONE payment file uploaded per
                        day. If one has already been uploaded today, this tool
                        will prevent an additional upload or overwrite. Try
                        again tomorrow.
                      </p>
                      {/* Already inputted rows for the Payment CSV */}
                      <th>Payment File CSV Preview:</th>
                      <table>
                        <tr>
                          <td>REFERENCE</td>
                          <td>VIN</td>
                          <td>AMOUNT</td>
                          <td>EFFECTIVE DATE</td>
                        </tr>
                        {rows.map((row, index) => (
                          <tr key={index}>
                            <td>{row.referenceNumber}</td>
                            <td>{row.vin}</td>
                            <td>{row.amount}</td>
                            <td>{row.effectiveDate}</td>
                          </tr>
                        ))}
                        {/* Input rows for the Payment CSV */}
                        {}
                      </table>
                      <AddPaymentFileRow handleAddRow={handleAddRow} />
                      <div className={s.pseudotabs}>
                        <button
                          className={`${s.tablink} ${s.selected}`}
                          onClick={uploadPaymentFile}
                          disabled={loading}
                        >
                          {loading ? 'loading...' : 'Generate & Upload to S3'}
                        </button>
                      </div>
                    </>
                  )}
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div>
          <button onClick={clearConfig}>Clear Configuration</button>
        </div>
      </Container>
    </>
  );
}
