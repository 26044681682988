import { quickLittlePut } from './quickLittleFetch';
import { getAuth } from 'firebase/auth';
import { Button, Stack } from '@mui/material';
import { useContext } from 'react';
import { ModalContext } from 'src/App';

export const createApplicationUpdateRequest = (term, id) => {
  const auth = getAuth();
  return (params) =>
    quickLittlePut(
      `applications/${id}`,
      () => {},
      { [term]: params },
      null,
      auth
    );
};

export const UpdateableField = ({ children, appId, obj, parameter }) => {
  const [modalContext, setModalContext] = useContext(ModalContext);

  return (
    <Stack direction="row">
      {children}
      <Button
        onClick={() =>
          setModalContext({
            open: true,
            saveFn: createApplicationUpdateRequest(obj, appId),
            parameter
          })
        }
      >
        Edit
      </Button>
    </Stack>
  );
};

export const getOptionsForParameter = (parameter: string) => {
  switch (parameter) {
    case 'housingOwnershipType':
      return ['Rent', 'Own'];
    case 'employmentType':
      return ['Unemployed', 'PartTime', 'FullTime', 'SelfEmployed'];
    // MT, Admins should NOT be allowed to directly edit LoanStatus here.
    // LoanStatus should be managed through LoanPro, which will then update tenetDb
    // through the loanProUpdateJob.
    case 'applicationStatus':
      return [
        'ACTIVE',
        'PROCESSING',
        'APPROVED',
        'CANCELLED_BY_ADMIN',
        'REJECTED_BY_ADMIN'
      ];
    default:
      return null;
  }
};
