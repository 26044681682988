const ENV = {
  local: {
    apiUrl: 'http://localhost:3003'
  },
  dev: {
    apiUrl: 'https://dev.api.velocity.baby/admin'
  },
  staging: {
    apiUrl: 'https://qa.api.velocity.baby/admin'
  },
  prod: {
    apiUrl: 'https://api.tenet.com/admin'
  }
};

const getEnvVars = () => {
  const releaseChannel = process.env.REACT_APP_STACK;
  console.log('RELEASE CHANNEL IS: ', releaseChannel);
  if (releaseChannel === undefined || releaseChannel === 'local') {
    return ENV.local;
  } else if (releaseChannel === 'dev') {
    return ENV.dev;
  } else if (releaseChannel === 'qa') {
    return ENV.staging;
  } else if (releaseChannel === 'prod') {
    return ENV.prod;
  }
  throw new Error('No environment specified.');
};

export default getEnvVars;
