import { getAuth } from 'firebase/auth';
import { useEffect } from 'react';
import {
  AuthProvider as ReactFireProvider,
  FirebaseAppProvider,
  useFirebaseApp,
  useSigninCheck
} from 'reactfire';
import { firebaseConfig } from 'src/hooks/auth/useAuth';

const ReactFireAuthProvider: React.FC = ({ children }) => {
  const auth = getAuth(useFirebaseApp());

  return <ReactFireProvider sdk={auth}>{children}</ReactFireProvider>;
};

const AuthProvider: React.FC = ({ children }) => (
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <ReactFireAuthProvider>{children}</ReactFireAuthProvider>
  </FirebaseAppProvider>
);

export default AuthProvider;
