/* this module is a little seedling!
   here are some happy little trees :3

       #o#                 #o#
       ####o#            ####o#
      #o# \#|_#,#       #o# \#|_#,#
     ###\ |/   #o#     ###\ |/   #o#
      # {}{      #     # {}{      #
         }{{             }{{
        ,'  `          ,'  `

TODO: Proper hooks and fetching system.
*/

import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { Auth } from 'firebase/auth';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useAuth } from 'src/hooks/auth/useAuth';

import getEnvVars from './env';

const env = getEnvVars();

function QuickLittleGet(endpoint: string, callback: Function) {
  // behold, a bold and spurious violation of hooks rule 3
  const navigate = useNavigate();
  const auth = useAuth();
  const effect = () => {
    const inner = async () => {
      const token = (await auth.getToken()) as string;
      const rpcConfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      };
      const url = `${env.apiUrl}/${endpoint}`;

      axios
        .create()
        .get(url, rpcConfig)
        .then((res) => {
          callback(res.data);
          console.log(`New GET from [${url}]`, res.data);
        })
        .catch((error) => {
          if (error instanceof AxiosError) {
            if (error.response.status === 401) {
              // Authorization has expired.
              navigate('/');
            }
          } else {
            throw error;
          }
        });
    };
    // Go ahead and drop the promise, who cares?
    inner();
  };
  return effect;
}

// This is just the "QuickLittleGet" without the nonsense
async function lessDumbGet(endpoint: string, authToken: string) {
  const token = authToken;
  const rpcConfig = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  };
  const url = `${env.apiUrl}/${endpoint}`;

  const resValue = axios
    .create()
    .get(url, rpcConfig)
    .then((res) => {
      console.log(`New GET from [${url}]`, res.data);
      return res.data;
    })
    .catch((error) => {
      if (error instanceof AxiosError) {
        throw error;
      }
    });
  return resValue;
}

const useLittleGet = (
  endpoint: string,
  config: AxiosRequestConfig,
  callback: Function
) => {
  const navigate = useNavigate();
  const auth = useAuth();

  const fetchWithSideEffects = useCallback(async () => {
    const token = (await auth.getToken()) as string;
    const rpcConfig = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    };
    const url = `${env.apiUrl}/${endpoint}`;

    axios
      .create()
      .get(url, { ...rpcConfig, ...config })
      .then((res) => {
        callback(res.data);
        console.log(`New GET from [${url}]`, res.data);
      })
      .catch((error) => {
        if (error instanceof AxiosError) {
          if (error.response.status === 401) {
            // Authorization has expired.
            navigate('/');
          }
        } else {
          throw error;
        }
      });
  }, [navigate, auth, endpoint, config, callback]);

  // TODO: add loading, better error handling, extensibility
  return { fetchWithSideEffects };
};

async function quickLittlePost(
  endpoint: string,
  callback: Function,
  data: object,
  navigate: any,
  auth: any
) {
  const token = await auth.getToken();
  const rpcConfig = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${token}`
    }
  };
  const url = `${getEnvVars().apiUrl}/${endpoint}`;

  return axios
    .create()
    .post(url, data, rpcConfig)
    .then((res) => {
      callback(res.data);
      console.log(`New POST from [${url}]`, res.data);
    })
    .catch((error) => {
      console.log('OH NO, AN ERROR!', error);
      if (
        error instanceof AxiosError &&
        error.response &&
        error.response.status === 401
      ) {
        // Authorization has expired.
        console.warn('Authorization has expired.');

        navigate('/');
      } else {
        throw error;
      }
    });
}

async function quickLittlePut(
  endpoint: string,
  callback: Function,
  data: object,
  navigate: any,
  auth: Auth
) {
  const token = await auth.currentUser.getIdToken();
  const rpcConfig = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${token}`
    }
  };
  const url = `${getEnvVars().apiUrl}/${endpoint}`;

  return axios
    .create()
    .put(url, data, rpcConfig)
    .then((res) => {
      callback(res.data);
      console.log(`New PUT from [${url}]`, res.data);
    })
    .catch((error) => {
      console.log('OH NO, AN ERROR!', error);
      if (
        error instanceof AxiosError &&
        error.response &&
        error.response.status === 401
      ) {
        // Authorization has expired.
        console.warn('Authorization has expired.');

        navigate('/');
      } else {
        throw error;
      }
    });
}

export {
  QuickLittleGet as quickLittleGet,
  lessDumbGet,
  quickLittlePost,
  quickLittlePut,
  useLittleGet
};
