import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { useFirebaseApp, AppCheckProvider } from 'reactfire';

import { Button, CssBaseline, Modal } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import AuthProvider from './providers/authProvider';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState
} from 'react';
import { getOptionsForParameter } from './util/updateApplication';

const APP_CHECK_TOKEN = process.env.REACT_APP_FIREBASE_RECAPTCHA;

const defaultModalConfig: {
  open: boolean;
  parameter?: string;
  saveFn?: Function;
} = {
  open: false
};
type modalSetter = Dispatch<SetStateAction<typeof defaultModalConfig>>;
type contextType = [typeof defaultModalConfig, modalSetter];
export const ModalContext = createContext([
  defaultModalConfig,
  (() => {}) as modalSetter
] as contextType);

type refreshSetter = Dispatch<SetStateAction<number>>;
type rType = [number, refreshSetter];
export const RefreshContext = createContext([
  0,
  (() => {}) as refreshSetter
] as rType);

const ModalProvider = () => {
  const [context, setContext] = useContext(ModalContext);
  const [refreshCounter, setRefreshCounter] = useContext(RefreshContext);
  const [state, setState] = useState('');

  const options = getOptionsForParameter(context.parameter);

  return (
    <Modal sx={{ background: '#ffffff80' }} open={context.open}>
      <div>
        <Button
          onClick={() => setContext({ open: false })}
          style={{ marginLeft: 'auto' }}
        >
          Close without Saving
        </Button>
        <div>
          Please enter the new value for this field: {context.parameter}
        </div>
        {!!options ? (
          options.map((option) => (
            <Button
              onClick={() => {
                (async () => {
                  await context.saveFn({ [context.parameter]: option });
                  setRefreshCounter((count) => count + 1);
                })();
              }}
            >
              {option}
            </Button>
          ))
        ) : (
          <input value={state} onChange={(e) => setState(e.target.value)} />
        )}
        <Button
          onClick={() => {
            (async () => {
              await context.saveFn({
                [context.parameter]: !!options ? null : state
              });
              setRefreshCounter((count) => count + 1);
            })();
          }}
        >
          {!!options ? 'Delete field' : 'Save'}
        </Button>
      </div>
    </Modal>
  );
};

const AppCheckSetupProvider: React.FC = ({ children }) => {
  const app = useFirebaseApp(); // a parent component contains a `FirebaseAppProvider`

  const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(APP_CHECK_TOKEN),
    isTokenAutoRefreshEnabled: true,
  });
  return <AppCheckProvider sdk={appCheck}>{children}</AppCheckProvider>;
}

function App() {
  const content = useRoutes(router);
  const [context, setContext] = useState(defaultModalConfig);
  const [refresh, setRefresh] = useState(0);

  return (
    <AuthProvider>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <RefreshContext.Provider value={[refresh, setRefresh]}>
            <AppCheckSetupProvider>
              <ModalContext.Provider value={[context, setContext]}>
                <ModalProvider />
                <CssBaseline />
                {content}
              </ModalContext.Provider>
            </AppCheckSetupProvider>
          </RefreshContext.Provider>
        </LocalizationProvider>
      </ThemeProvider>
    </AuthProvider>
  );
}
export default App;
