import {
  Box,
  Tooltip,
  Badge,
  TooltipProps,
  tooltipClasses,
  styled,
  useTheme
} from '@mui/material';
import { maxHeight } from '@mui/system';
import { Link } from 'react-router-dom';

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        margin: 0 auto;
        font-weight: ${theme.typography.fontWeightBold};
`
);

const LogoSignWrapper = styled(Box)(
  () => `
        width: 190px;
        height: 45px;
`
);

const LogoSignInner = styled(Box)(
  ({ theme }) => `
        width: 16px;
        height: 16px;
        position: absolute;
        top: 12px;
        left: 12px;
        z-index: 5;
        border-radius: ${theme.general.borderRadiusSm};
        background: ${theme.header.background};
`
);

const TooltipWrapper = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.trueWhite[100],
    color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'bold',
    borderRadius: theme.general.borderRadiusSm,
    boxShadow:
      '0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)'
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.trueWhite[100]
  }
}));

function TenetLogoWithText() {
  return (
    <img
      style={{ maxHeight: '100%' }}
      src="/static/images/logo/tenet-with-text.png"
      alt="QA"
    />
  );
}

function TenetLogoWhite() {
  return (
    <img
      style={{ maxHeight: '100%' }}
      src="/static/images/logo/tenet-with-text-white.png"
      alt="QA"
    />
  );
}

function TenetLogo() {
  return (
    <img
      style={{ maxHeight: '100%' }}
      src="/static/images/logo/tenet.png"
      alt="QA"
    />
  );
}

function Logo() {
  const theme = useTheme();

  return (
    <TooltipWrapper title="Tenet Admin Dashboard" arrow>
      <LogoWrapper to="/overview">
        <Badge
          sx={{
            '.MuiBadge-badge': {
              fontSize: theme.typography.pxToRem(18),
              color: 'black',
              right: -30,
              top: 0
            }
          }}
          color="info"
          badgeContent="admin"
        >
          <LogoSignWrapper>
            <TenetLogoWhite />
          </LogoSignWrapper>
        </Badge>
      </LogoWrapper>
    </TooltipWrapper>
  );
}

function LogoWithText() {
  const theme = useTheme();

  return (
    <TooltipWrapper title="Tenet Admin Dashboard" arrow>
      <LogoWrapper to="/overview">
        <LogoSignWrapper>
          <TenetLogoWithText />
        </LogoSignWrapper>
      </LogoWrapper>
    </TooltipWrapper>
  );
}

export default Logo;
export { LogoWithText };
