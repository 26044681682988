import { Suspense, lazy } from 'react';
import { RouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';

import BaseLayout from 'src/layouts/BaseLayout';
import SidebarLayout from 'src/layouts/SidebarLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import WestlakeToolsPage from 'src/content/pages/Westlake';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const Overview = Loader(lazy(() => import('src/content/overview')));

const UsersTable = Loader(lazy(() => import('src/content/pages/Users')));

const UserPage = Loader(lazy(() => import('src/content/pages/User')));

const ApplicationsPage = Loader(
  lazy(() => import('src/content/pages/Applications'))
);

const ApplicationPage = Loader(
  lazy(() => import('src/content/pages/Application'))
);

const LoansPage = Loader(lazy(() => import('src/content/pages/Loans')));

const LoanPage = Loader(lazy(() => import('src/content/pages/Loan')));

const PriorityPage = Loader(lazy(() => import('src/content/pages/Priority')));

const DealershipsPage = Loader(
  lazy(() => import('src/content/pages/Dealerships'))
);

const DealershipPage = Loader(
  lazy(() => import('src/content/pages/Dealership'))
);

const ImpersonationPage = Loader(
  lazy(() => import('src/content/pages/Impersonation'))
);

const DigifiPage = Loader(lazy(() => import('src/content/pages/Digifi')));

const Westlake = Loader(lazy(() => import('src/content/pages/Westlake')));

const InsightsUserPage = Loader(
  lazy(() => import('src/content/pages/Insights/User'))
);
const InsightsUsersPage = Loader(
  lazy(() => import('src/content/pages/Insights/Users'))
);
const InsightsCarPage = Loader(
  lazy(() => import('src/content/pages/Insights/Car'))
);

const InsightsFetchPage = Loader(
  lazy(() => import('src/content/pages/Insights/Fetch'))
);

const CalculatorPage = Loader(
  lazy(() => import('src/content/pages/Calculator'))
);

const ExperimentsPage = Loader(
  lazy(() => import('src/content/pages/Experiments'))
);

const TestingPage = Loader(lazy(() => import('src/content/pages/Testing')));
const CustomerUpdate = Loader(
  lazy(() => import('src/content/pages/CustomerUpdate'))
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Overview />
      },
      {
        path: 'overview',
        element: <Navigate to="/" replace />
      }
    ]
  },
  {
    path: 'users',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <UsersTable />
      },
      {
        path: ':id',
        element: <UserPage />
      }
    ]
  },
  {
    path: 'applications',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <ApplicationsPage />
      },
      {
        path: ':id',
        element: <ApplicationPage />
      }
    ]
  },
  {
    path: 'priority',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <PriorityPage />
      },
      {
        path: ':id',
        element: <ApplicationPage />
      }
    ]
  },
  {
    path: 'loans',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <LoansPage />
      },
      {
        path: ':id',
        element: <LoanPage />
      }
    ]
  },
  {
    path: 'dealerships',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <DealershipsPage />
      },
      {
        path: ':id',
        element: <DealershipPage />
      }
    ]
  },
  {
    path: 'calculator',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <CalculatorPage />
      }
    ]
  },
  {
    path: 'insights',
    element: <SidebarLayout />,
    children: [
      {
        path: 'users',
        children: [
          {
            path: '',
            element: <InsightsUsersPage />
          },
          {
            path: ':id',
            element: <InsightsUserPage />
          }
        ]
      },
      {
        path: 'cars',
        children: [
          {
            path: ':id',
            element: <InsightsCarPage />
          }
        ]
      },
      {
        path: 'fetches',
        children: [
          {
            path: ':id',
            element: <InsightsFetchPage />
          }
        ]
      }
    ]
  },
  {
    path: 'impersonation',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <ImpersonationPage />
      }
    ]
  },
  {
    path: 'digifi',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <DigifiPage />
      }
    ]
  },
  {
    path: 'westlake',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <WestlakeToolsPage />
      }
    ]
  },
  {
    path: 'testing',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <TestingPage />
      }
    ]
  },
  {
    path: 'customer-update',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <CustomerUpdate />
      }
    ]
  },

  {
    path: 'experiments',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <ExperimentsPage />
      }
    ]
  }
];

export default routes;
